<template>
  <VAuthBlock :title="!hasPromoCode ? $route.meta.heading || 'Create account' : 'Register using a code'">
    <a-form :form="form" @submit="register">

      <div v-if="!hasPromoCode">
        <a-form-item class="mb-6">
          <a-button
              type="default"
              block
              size="large"
              class="btn-action"
              @click="makeSocialAuth('google')"
          >
            <a-icon :component="iconGoogle"></a-icon>
            Continue with Google
          </a-button>
        </a-form-item>

        <a-divider/>
      </div>

      <a-form-item :validate-status="nameError() ? 'error' : ''" :help="nameError() || ''" class="mt-6">
        <a-input
            size="large"
            placeholder="Name and Last name"
            name="name"
            v-decorator="['name', { rules: [{ required: true, message: 'Input your name' }] }]"
        >
          <a-icon slot="prefix" type="user" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item
          :validate-status="emailError() ? 'error' : ''"
          :help="emailError() || ''"
      >
        <a-input
            size="large"
            placeholder="Email"
            v-decorator="['email', { rules: [{type: 'email', message: 'Input valid email'}, { required: true, message: 'Input your email' }], initialValue: $route.query.email ? decodeURIComponent($route.query.email) : '', }]"
        >
          <a-icon slot="prefix" type="mail" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''">
        <a-input-password
            size="large"
            placeholder="Password"
            autocomplete="off"
            v-decorator="['password', { rules: [{ required: true, message: 'Input your password' }, {min: 8, message: 'Min. 8 characters'}] }]"
        >
          <a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
        </a-input-password>
      </a-form-item>
      <a-form-item v-if="hasPromoCode" :validate-status="promoCodeError() ? 'error' : ''"
                   :help="promoCodeError() || ''">
        <a-input
            size="large"
            placeholder="Your code here"
            autocomplete="off"
            v-decorator="['promocode', { rules: [{ required: true, message: 'Input your code' }] }]"
        >
          <a-icon slot="prefix" type="fire" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item class="mb-0">
        <div class="flex justify-between gap-8">
          <VButton type="primary"
                   size="large"
                   html-type="submit"
                   :disabled="hasErrors(form.getFieldsError())"
                   :loading="isLoading"
                   style="min-width: 152px;"
          >
            Register
          </VButton>
          <div class="flex items-center justify-center">
            <VButton type="link" size="large" @click="$router.push({name: 'Login', query: $route.query})">Sign in
            </VButton>
          </div>
        </div>
      </a-form-item>
    </a-form>
  </VAuthBlock>
</template>

<script>
import {REGISTER} from "@/store/actions.type";
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from "vuex";
import VAuthBlock from "@/components/VAuthBlock";
import {GoogleSvg} from "@/helpers/customIcons";
import socialAuth from "@/mixins/socialAuth";
import VButton from "../components/VButton";

export default {
  name: "Register",
  components: {VAuthBlock, VButton},
  mixins: [socialAuth],
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, {name: 'register_form'}),
      iconGoogle: GoogleSvg,
      hasPromoCode: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });

    // Check if the referral is from AppSumo
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const hasPromoCodeInSession = window.sessionStorage.getItem('promocode')
    this.hasPromoCode = urlParams.get('promocode') === 'true' || hasPromoCodeInSession
    if (this.hasPromoCode && !hasPromoCodeInSession) {
      window.sessionStorage.setItem('promocode', true)
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoading'])
  },
  methods: {
    // Only show error after a field is touched.
    emailError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('email') && getFieldError('email');
    },
    // Only show error after a field is touched.
    passwordError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('password') && getFieldError('password');
    },
    // Only show error after a field is touched.
    nameError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('name') && getFieldError('name');
    },
    // Only show error after a field is touched.
    promoCodeError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('promocode') && getFieldError('promocode');
    },
    register(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch(`auth/${REGISTER}`, {
            ...values,
            invitationToken: this.$route.query?.invitation_token,
          })
              .then(() => {
                this.$router.push('/');
              })
        }
      });
    },
  },
}
</script>

<style scoped>

</style>
