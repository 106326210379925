<template>
  <div class="flex w-full pt-8 pb-20 space-x-20">
    <div class="w-7/12">

      <h2 class="text-3xl text-dark_gray-500 mt-7 mb-5">
        <span>
          Unlock <span class="font-bold">all features</span>:
        </span>
      </h2>

      <div class="flex items-start space-x-5">
        <div v-for="(column, index) in features"
             :key="index"
             class="space-y-7"
             style="width: 268px;">
          <div v-for="feature in column"
               :key="feature.icon"
               class="flex items-center space-x-4 text-dark_gray-500 text-lg leading-tight"
               style="height: 58px;"
          >
            <VIcon :name="feature.icon" style="font-size: 58px;" class="flex-none"></VIcon>
            <span>{{ feature.text }}</span>
          </div>
        </div>
      </div>

      <h2 class="text-3xl text-dark_gray-500 mt-8 mb-4">
        Choose your plan
      </h2>


      <div class="bg-white rounded-xl p-6 shadow-custom">
        <a-skeleton :loading="isLoading" :paragraph="{ rows: 5 }" active avatar>
          <div class="flex space-x-12">
            <div v-if="currentPlan" class="flex flex-grow space-x-2.5">
              <div class="flex-none leading-none" style="font-size: 60px;">
                <VIcon :name="currentPlan.icon"/>
              </div>
              <div class="flex flex-col justify-between flex-grow">
                <div>
                  <div class="flex items-center justify-between font-bold leading-tight">
                    <span class="text-xl">Pro plan</span>
                  </div>
                  <div class="text-base text-character_gray-300 leading-tight">
                    {{ currentPlan.responses }} responses, unlimited surveys and questions
                  </div>
                </div>
                <div>
                  <div class="flex justify-between text-character_gray-300 text-lg leading-tight">
                    <span class="font-semibold">Subtotal</span>
                    <a-skeleton :loading="isLoadingCheckout" active :title="false" :paragraph="{rows: 1, width: 50}"
                                class="item-skeleton">
                      <span>{{ currentPlan.currencySymbol }}{{ subtotalRecurring }}</span>
                    </a-skeleton>
                  </div>
                  <a-divider style="margin-top: 8px;"/>
                  <div class="flex justify-between text-base leading-tight font-light">
                    <span>Sales tax (VAT)</span>
                    <a-skeleton :loading="isLoadingCheckout" active :title="false" :paragraph="{rows: 1, width: 50}"
                                class="item-skeleton">
                      <span>{{ currentPlan.currencySymbol }}{{ tax }}</span>
                    </a-skeleton>
                  </div>
                  <a-divider style="margin-bottom: 8px;"/>
                  <div class="flex justify-between text-lg leading-tight">
                    <span class="font-semibold"
                          v-if="total <= 0">Total due today</span>
                    <span class="font-semibold" v-else>Total</span>
                    <a-skeleton :loading="isLoadingCheckout" active :title="false" :paragraph="{rows: 1, width: 50}"
                                class="item-skeleton">
                      <span class="font-bold">{{ currentPlan.currencySymbol }}{{ total }}</span>
                    </a-skeleton>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-none space-y-3">
              <div class="flex justify-end space-x-3">

                <VPlanRadio v-for="plan in plansComputed"
                            :key="plan.product_id"
                            :title="plan.name"
                            :price="+(plan.subscription.price.net).toFixed(0)"
                            :currency-symbol="plan.currencySymbol"
                            :value="plan.product_id"
                            v-model="selectedPlan"
                            :ribbon="plan.subscription.interval === 'year' ? {color: '#FF8743', text: '2 months free'} : {}"
                >
                  <template #extra>
                    <div>
                      <div v-if="plan.subscription.interval === 'year'"
                           class="text-sm leading-4"
                           style="color: #7F8CA7;">
                        {{ plan.currencySymbol }}{{ (plan.subscription.price.net / 12).toFixed(0) }} per month
                      </div>
                      <div class="text-sm leading-4 text-blue-500">
                        <span v-if="plan.responses.toLowerCase() === 'unlimited'">
                          <VIcon name="infinite" class="text-blue-500"/>
                        </span>
                        <span v-else>
                          {{ plan.responses }}
                        </span>
                        responses
                      </div>
                    </div>
                  </template>
                </VPlanRadio>

              </div>
              <div class="w-full text-base rounded-lg bg-dark_gray-500 py-1.5 px-4 text-center leading-tight"
                   style="background: var(--color-layout); color: #7F8CA7;">
                To unlock <span class="font-bold">unlimited responses</span><br> subscribe Annual!
              </div>
            </div>
          </div>
        </a-skeleton>
      </div>


    </div>

    <div class="w-5/12 pt-7">
      <div class="flex flex-col h-full bg-white rounded-xl p-6 shadow-custom">
        <div class="flex items-center justify-between">
          <span class="font-semibold" style="font-size: 40px;">Price</span>
          <a-skeleton v-if="currentPlan" :loading="isLoadingCheckout" active :title="false"
                      :paragraph="{rows: 1, width: 70}" class="item-skeleton">
            <span class="font-light leading-tight"
                  style="font-size: 32px; color: #262626; opacity: 0.6;">
              {{ currentPlan.currencySymbol }}{{ total }}
            </span>
          </a-skeleton>
        </div>

        <div class="checkout-container flex-grow w-full m-auto"></div>

        <VBadges class="items-center justify-center space-x-3"/>

      </div>
    </div>

  </div>
</template>

<script>
import VIcon from "../components/VIcon";
import VPlanRadio from "../components/VPlanRadio";
import VBadges from "../components/VBadges";
import {FETCH_PLANS, GET_PAY_LINK} from "../store/actions.type";
import {mapGetters} from "vuex";
import _ from "lodash";
import {plansMixin} from "../mixins/plans";

const paddle = window.Paddle;
let checkoutData = {};

export default {
  name: "Upgrade",

  components: {VIcon, VPlanRadio, VBadges},

  mixins: [plansMixin],

  created() {
    this.$store.dispatch(`billing/${FETCH_PLANS}`)
        .then(() => {
          this.selectedPlan = _.find(this.plans, 'isSelected')?.product_id;
        })

    if (process.env.NODE_ENV === "dev") {
      paddle.Environment.set('sandbox');
    }

    paddle.Setup({
      vendor: Number(process.env.VUE_APP_PADDLE_VENDOR_ID),
      eventCallback: eventData => {
        checkoutData = eventData;
        this.handleDiscount(checkoutData);
      }
    });
  },

  data() {
    return {
      selectedPlan: undefined,
      subtotalRecurring: undefined,
      total: undefined,
      totalRecurring: undefined,
      tax: undefined,
      isLoadingCheckout: true,
    }
  },

  watch: {
    'currentPlan.planId': {
      handler: function (planId) {
        this.setupCheckout(planId, this.country);
      },
    }
  },

  computed: {
    ...mapGetters('auth', ["user"]),
    ...mapGetters('billing', ["country", "isLoading"]),

    currentPlan: function () {
      return _.find(this.plansComputed, ['product_id', this.selectedPlan]);
    },

    features: function () {
      return {
        column1: [
          {icon: 'feature-responses', text: `${this.currentPlan?.responses} responses`},
          {icon: 'feature-company-logo', text: 'Your company logo'},
          {icon: 'feature-thank-you', text: 'Customized screens'},
        ],
        column2: [
          {icon: 'feature-unlimited', text: 'Unlimited surveys and questions'},
          {icon: 'feature-white-label', text: 'Remove MS branding'},
          {icon: 'feature-cta', text: 'Call-To-Action'},
        ],
        column3: [
          {icon: 'feature-unlimited', text: 'Remove character limit'},
          {icon: 'feature-custom-domain', text: 'Custom domain + SSL cert'},
          {icon: 'feature-auto-redirect', text: 'Redirect on completion'},
        ],
      }
    }
  },

  methods: {
    setupCheckout(planId, country) {
      this.isLoadingCheckout = true;
      const key = 'subscription-created';

      this.$store.dispatch(`billing/${GET_PAY_LINK}`, {productId: planId})
          .then(data => {
            paddle.Checkout.open({
              override: data.url,
              method: 'inline',
              email: this.user.email,
              locale: 'en',
              country,
              allowQuantity: false,
              disableLogout: true,
              frameTarget: 'checkout-container',
              frameInitialHeight: 416,
              frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;',
              loadCallback: () => {
                this.updatePrices(checkoutData);
              },
              successCallback: () => {
                this.$notification.open({
                  key,
                  duration: 5,
                  message: <div class="text-positive text-base mb-2">Hooray! You have become a Pro!</div>,
                  description: 'Congratulations on becoming a PRO! You will now have access to all of our PRO features and collecting extra responses.',
                  icon: <a-icon type="crown" class="text-positive"/>,
                  style: {width: '467px', marginLeft: `${384 - 467}px`},
                  btn: h => {
                    return h(
                        'a-button',
                        {
                          props: {
                            type: 'primary',
                            size: 'small',
                          },
                          on: {
                            click: () => {
                              this.$notification.close(key);
                            }
                          },
                        },
                        'Got it!',
                    );
                  }
                });

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              },
            });
          });
    },

    updatePrices(data) {
      const prices = data.eventData.checkout.prices.customer;
      const recurringPrices = data.eventData.checkout.recurring_prices.customer;

      this.subtotalRecurring = (recurringPrices.total - recurringPrices.total_tax).toFixed(2);
      this.totalRecurring = Number(recurringPrices.total).toFixed(2);
      this.total = Number(prices.total).toFixed(2);
      this.tax = Number(recurringPrices.total_tax).toFixed(2);

      this.isLoadingCheckout = false;
    },

    handleDiscount(data) {
      this.total = Number(data.eventData.checkout.prices.customer.total).toFixed(2);
    }

  },
}
</script>

<style lang="less" scoped>
.shadow-custom {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}
</style>
